/**
 * Collection of methods to handle the gallery
 * except for the dropzone plugin, that is included in the entries
 */

// handle the toggles for new, view and set
$(document).on('click', '.action-bar .icon-new, .action-bar .icon-view, .action-bar .icon-set', function() {
    var $parent = $(this).parents('.dz-preview');
    if ( ! $parent.hasClass('is-deleting')) {
        var id = $parent.data('id');

        // this makes sure the view and new icons can both use this method
        var url = '/gallery/new/' + id;
        var icon_class = '.icon-new';
        if ($(this).hasClass('icon-view')) {
            url = '/gallery/view/' + id;
            icon_class = '.icon-view';
        } else if ($(this).hasClass('icon-set')) {
            url = '/gallery/set/' + id;
            icon_class = '.icon-set';
        }

        // toggle the active class, the result coming from the server will set it to the correct state
        $(this).toggleClass('active');

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                var $icon = $('*[data-id="' + data['model_photo_id'] + '"]').find(icon_class);
                $icon.removeClass('active');
                if (data['toggle_state']) {
                    $icon.addClass('active');
                }
            }
        }).always(function () {
            unsetLoading();
        }).fail(function () {
            ajaxError();
        });
    }
});

// handle the delete for a model photo
$(document).on('click', '.action-bar .icon-delete', function() {
    if (confirm('Weet u het zeker?')) {
        var $parent = $(this).parents('.dz-preview');
        var id = $parent.data('id');
        var url = '/gallery/delete/' + id;
        $parent.addClass('is-deleting');

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function () {
                $parent.fadeOut(300, function () {
                    $(this).remove();
                });
            }
        }).always(function () {
            unsetLoading();
        }).fail(function () {
            ajaxError();
        });
    }
});

function initGallery($parentEntry, fetchedId) {
    $photos_container = $parentEntry.find("ul.photos-container");

    // make the gallery entries sortable
    $photos_container.sortable({
        update: function (event, ui) {
            var data = {new_position: (ui.item.index() + 1)};
            var url = '/gallery/sort/' + ui.item.data('id');

            $.ajax({
                data: data,
                type: 'POST',
                url: url
            });
        }
    });

    // add an upload dropzone to this model entry
    var url = '/gallery/upload/' + fetchedId;
    var gallery_dropzone = $parentEntry.find('.dropzone').dropzone({
        url: url,
        method: 'POST',
        paramName: 'image',
        dictDefaultMessage: 'Sleep hier de bestanden die je wilt uploaden',
        previewsContainer: '.photos-container',
        acceptedFiles: 'image/*',
        sending: function(file, xhr, formData) {
            // Pass token. You can use the same method to pass any other values as well such as a id to associate the image with for example.
            formData.append("_token", $('[name=_token').val()); // Laravel expect the token post value to be named _token by default
        },
        success: function(file, xhr, event) {
            // Append new photo
            $photos_container.append($(xhr['html']));

            // update the photo date value
            $parentEntry.find('.photo-date').text(xhr['date_photos']);

            // remove the default dropzone HTML created HTML
            $('div.dz-preview').remove();
        }
    });
}
