/**
 * Collection of methods to handle the overviews (sorting, searching, pagination)
 */

// we have to prevent older views from showing up, this is usually visible when searching
var currentOverviewRefresh = 0;

// search when a key is pressed in the input field
// the 'currentOverviewRefresh' var will make sure only the latest version will be shown
$(document).on('keyup search', '.search-form input', function() {
    var $parent = $(this).parents('.overview-container');
    fetchOverview($parent, true);
});

// search when the submit icon is clicked
$(document).on('click', '.search-form button', function(e) {
    e.preventDefault();

    var $parent = $(this).parents('.overview-container');
    fetchOverview($parent, true);
});

// handle sortable column clicks
$(document).on('click', '.sortable', function() {
    var $parent = $(this).parents('.overview-container');

    // set the input field to the correct order value, needed for fetchOverview
    var orderBy = $(this).data('order-by');
    $parent.find('input[name="order-column"]').val(orderBy);

    // set the input field to the correct order value, needed for fetchOverview
    if ($(this).hasClass('active-desc') || !$(this).hasClass('active')) {
        orderDirection = 'asc';
        $(this).removeClass('active-desc');
    } else {
        orderDirection = 'desc';
        $(this).addClass('active-desc');
    }
    $(this).addClass('active');

    $parent.find('input[name="order-direction"]').val(orderDirection);

    var $that = $(this);
    $parent.find('.sortable').each(function () {
        if ($(this).data('order-by') != $that.data('order-by')) {
            $(this).removeClass('active active-desc');
        }
    });

    fetchOverview($parent, true);
});

// pagination with boundaries check
$(document).on('click', '.pagination', function() {
    var $parent = $(this).parents('.overview-container');
    var $pagination = $parent.find('input[name="pagination"]');

    if ( ! $(this).hasClass('disabled')) {
        if ($(this).hasClass('next')) {
            var current = ((parseInt($pagination.val()) + 1) * 10);
            var total = $parent.find('.total-available').text();
            if (current >= total) {
                $parent.find('.pagination.next').addClass('disabled');
            } else {
                $pagination.val(parseInt($pagination.val()) + 1);
                fetchOverview($parent);
            }
        } else if ($(this).hasClass('prev')) {
            if ($pagination.val() > 0) {
                $pagination.val(parseInt($pagination.val()) - 1);
                fetchOverview($parent);
            }
        }
    }
});

// show the correct overview when clicking on the accordion label
$(document)
    .on('click', '.accordion-state', function(e) {
        var $parent = $(this).parent('.accordion');
        if ( ! $parent.hasClass('open')) {
            $('.accordion.open').removeClass('open').find('.drawer').slideUp();
            $parent.addClass('open').find('.drawer').slideDown();
            resetDeleteIds();
        }
    })

    .on('click', '.toggle-accordion', function (e) {

        e.preventDefault();
        e.stopPropagation();

        var $parent = $(this).closest('.accordion');
        if($parent.hasClass('open')) {
            $parent.removeClass('open').find('.drawer').slideUp();
        } else {
            $('.accordion.open').removeClass('open').find('.drawer').slideUp();
            $parent.addClass('open').find('.drawer').slideDown();
            resetDeleteIds();
        }
    });

// init the first panel to be open
$(document).ready(function() {
    $('.accordion').first().addClass('open').find('.drawer').slideDown();
});

// show the correct overview when clicking on a tab
$(document).on('click', '.options .text-only, .options .advanced-search-button', function() {
    var $parent = $(this).parents('.overview-container');
    $parent.find('input[name="overview-type"]').val($(this).data('overview'));
    $parent.find('.options .text-only.active, .options .advanced-search-button.active').removeClass('active');
    $parent.find('.search-form input').val('');
    $(this).addClass('active');
    fetchOverview($parent, true);
});

// show the advanced search form
$(document).on('click', '.options .advanced-search-button', function() {
    var $parent = $(this).parents('.overview-container');
    $parent.find('input[name="overview-type"]').val($(this).data('overview'));
    $parent.find('.options .text-only.active, .options .advanced-search-button.active').removeClass('active');
    $parent.find('.search-form input').val('');
    $(this).addClass('active');

    setLoading();

    var controller = $parent.data('controller') + '/advanced-search';

    currentOverviewRefresh++;

    var $table_entries = $parent.find('main.table-entries');

    var data = {
        currentOverviewRefresh: currentOverviewRefresh,
        overviewType: $parent.find('input[name="overview-type"]').val(),
    };

    $.ajax({
        method: 'GET',
        url: controller,
        data: data,
        dataType: 'json',
        success: function(data) {
            if (data['currentOverviewRefresh'] == currentOverviewRefresh) {
                $table_entries.replaceWith(data['content']);
                $parent.find('main.drawer header, main.drawer footer').hide();
                $parent.find('.accordion-state').click();
            }
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// array to hold the id's to be deleted for this overview
var deleteIds = [];

// reset the array of IDs to be deleted, to prevent deletion of models from other overviews
// todo: [jef] This sounds REALLY tricky to me!
function resetDeleteIds() {
    deleteIds = [];
    $('header.table-row .actions .icon-delete').text('');
}

// add this entry to the deleteIds array
$(document).on('click', '.table-entries .icon-delete', function() {
    var $parentOverview = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');

    var idFound = false;
    $.each(deleteIds, function(index, id) {
        if (id == $parentEntry.data('id')) {
            idFound = true;
            deleteIds.splice(index, 1);
        }
    });

    if ( ! idFound) {
        deleteIds.push($parentEntry.data('id'));
    }

    $parentOverview.find('header.table-row .actions .icon-delete').text((deleteIds.length > 0) ? deleteIds.length : '');
});

// delete/archive the selected deleteIds
// depending on what tab you are in, the items are either moved to archive, or deleted permanently
$(document).on('click', '.cell.actions .icon-delete', function() {
    if (confirm('Weet u zeker dat u dit element wilt verwijderen?')) {
        setLoading();

        var $parent = $(this).parents('.overview-container');
        var $parentEntry = $(this).parents('.item-container');
        // var url = $parent.data('controller') + '/delete/' + $parentEntry.data('id'); ?
        var url = $parent.data('controller') + '/archive/' + $parentEntry.data('id');

        currentOverviewRefresh++;

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function() {
                fetchOverview($parent);
            }
        }).always(function() {
            unsetLoading();
        }).fail(function() {
            ajaxError();
        });
    }
});

// method to load the overviews, based on hidden form inputs to control some settings
function fetchOverview($parent, resetPagination) {
    setLoading();

    var controller = $parent.data('controller');

    if (resetPagination) {
        $parent.find('input[name="pagination"]').val(0);
    }

    var pagination = $parent.find('input[name="pagination"]').val();

    $parent.find('.pagination.prev').removeClass('disabled');

    if (pagination <= 0) {
        $parent.find('.pagination.prev').addClass('disabled');
    }

    currentOverviewRefresh++;

    var $table_entries = $parent.find('main.table-entries');

    var data = {
        page: pagination,
        searchFor: $parent.find('.search-form input').val(),
        orderColumn: $parent.find('input[name="order-column"]').val(),
        orderDirection: $parent.find('input[name="order-direction"]').val(),
        currentOverviewRefresh: currentOverviewRefresh,
        overviewType: $parent.find('input[name="overview-type"]').val(),
        model_filters: ModelSearch && ModelSearch.currentFilters || []
    };

    $.ajax({
        method: 'GET',
        url: controller,
        data: data,
        dataType: 'json',
        success: function(data) {
            if (data['currentOverviewRefresh'] == currentOverviewRefresh) {
                $table_entries.replaceWith(data['content']);
                $parent.find('.currently-shown').text(data['currentlyShown']);
                $parent.find('.total-available').text(data['totalAvailable']);
                $parent.find('main.drawer').show();
                $parent.find('section.edit').hide();
                $parent.find('main.drawer header, main.drawer footer').show();

                $parent.find('.pagination.next').removeClass('disabled');
                if (data['currentlyShown'] >= data['totalAvailable']) {
                    $parent.find('.pagination.next').addClass('disabled');
                }

                $parent.find('.accordion-state').click();
            }
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}




