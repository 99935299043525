// clicking on the globe to indicate a model is visible on the website yes/no
$(document).on('click', '.models .item-container .icon-globe', function() {
    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/visability-website/' + $parentEntry.data('id');
    var $that = $(this);

    // toggle the green class, the result coming from the server will set it to the correct state
    $(this).toggleClass('green');

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function (data) {
            $that.removeClass('green');
            if (data['toggle_state']) {
                $that.addClass('green');
            }
        }
    }).always(function () {
        unsetLoading();
    }).fail(function () {
        ajaxError();
    });
});


function initSelect2Inputs() {
    $('.select2').select2({
        width: '100%'
    });
}

$(document).ajaxComplete(initSelect2Inputs);
initSelect2Inputs();

// clicking on the checkbox to select a model, should add it to the session
$(document).on('click', '.item-container .icon-checkbox-empty, .item-container .icon-check', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/select-id/' + $parentEntry.data('id');

    if ($(this).hasClass('icon-check')) {
        $(this).removeClass('icon-check').addClass('icon-checkbox-empty');
        url += '/remove';
    } else {
        $(this).addClass('icon-check').removeClass('icon-checkbox-empty');
        url += '/add';
    }

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            // nothing to do
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// adding extra settlements for a model
$(document).on('click', '.settlements-model-container .icon-arrow-up', function() {
    var $parent = $(this).parents('.overview-container');
    var $parentModel = $(this).parents('.settlements-model-container');

    handleModelSettlements($parent, $parentModel);
});

// handle changes in the model costs label and/or values
$(document).on('change', '.settlements-model-container .model-settlement-field', function() {
    var $parent = $(this).parents('.overview-container');
    var $parentModel = $(this).parents('.settlements-model-container');

    handleModelSettlements($parent, $parentModel);
});

// general method to handle updates done to the 'geboekte modellen' view
function handleModelSettlements($parent, $parentModel) {
    setLoading();

    var url = $parent.data('controller') + '/model-settlement-costs';
    var data = $parentModel.find('form').serialize();

    // disable the input fields, so further editing cannot be done untill the server responses
    // this is done to prevent the user from editing a second field, which will be replaced with
    // the serve response, which feels buggy
    $('.model-settlement-field').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            $parentModel.replaceWith(data['content']);
            $('.model-settlement-field').prop('disabled', false);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}

// remove model settlement
$(document).on('click', '.model-settlements-block .icon-kruis-1', function() {
    var $parent = $(this).parents('.overview-container');
    var $parentModel = $(this).parents('.settlements-model-container');

    setLoading();

    var url = $parent.data('controller') + '/remove-model-settlement/' ;
    var data = {
        'settlement_id': $(this).data('settlement-id'),
        'model_id': $(this).data('model-id')
    };

    $('.booked-model-costs-field').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            $parentModel.replaceWith(data['content']);
            $('.booked-model-costs-field').prop('disabled', false);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

var ModelSearch = (function handleModelSearch () {

    var filterState = null;
    var currentPage = null;
    var totalResults = null;

    $(document).on('submit', '#advanced-model-search-form', function (e) {
        e.preventDefault();

        var $parent = $(this).closest('.table');

        $.get(this.action, $(this).serialize(), function (response) {
            $('#advanced-model-search-results').html(response.content);
            filterState = response.filterState;
            totalResults = response.totalAvailable;

            $parent.find('.currently-shown').text(response.currentlyShown);
            $parent.find('.total-available').text(response.totalAvailable);
            // $parent.find('main.drawer').show();
            // $parent.find('section.edit').hide();
            // $parent.find('main.drawer header, main.drawer footer').show();

            $parent.find('.pagination.next').removeClass('disabled');
            if (response.currentlyShown >= response.totalAvailable) {
                $parent.find('.pagination.next').addClass('disabled');
            }

            $parent.find('.accordion-state').click();

            $('#advanced-model-search-form').hide();
            $('#advanced-model-search-results').show();
            $('[data-show-search-form]').show();
            $('[data-hide-search-form]').hide();
            $parent.closest('.overview-container').find('main.drawer header, main.drawer footer').show();
        });
    })
        .on('click', '[data-show-search-form]', function () {
            $('[data-show-search-form]').hide();
            $('[data-hide-search-form]').show();
            $('#advanced-model-search-form').show();
            $('#advanced-model-search-results').hide();
            $('#advanced-model-search-form')
                .closest('.overview-container')
                .find('main.drawer header, main.drawer footer')
                .hide();
        })
        .on('click', '[data-hide-search-form]', function () {
            $('[data-show-search-form]').show();
            $('[data-hide-search-form]').hide();
            $('#advanced-model-search-form').hide();
            $('#advanced-model-search-results').show();
            $('#advanced-model-search-form')
                .closest('.overview-container')
                .find('main.drawer header, main.drawer footer')
                .show();
        })
    ;

    return {
        get currentFilters() {
            return filterState && filterState.query.model_filters || [];
        }
    }
}());

// show the correct overview when clicking on a tab
$(document).on('click', '#reset-model-filters', function() {
    var $parent = $(this).parents('.overview-container');
    // $parent.find('input[name="overview-type"]').val($(this).data('overview'));
    // $parent.find('.options .text-only.active, .options .advanced-search-button.active').removeClass('active');
    // $parent.find('.search-form input').val('');
    // $(this).addClass('active');
    fetchSearch($parent);
});


// method to load the overviews, based on hidden form inputs to control some settings
function fetchSearch($parent) {
    setLoading();

    var $table_entries = $parent.find('main.table-entries');

    $.ajax({
        method: 'GET',
        url: '/model/reset-filters',
        dataType: 'json',
        success: function(data) {
            $table_entries.replaceWith(data['content']);
            // $parent.find('.currently-shown').text(data['currentlyShown']);
            // $parent.find('.total-available').text(data['totalAvailable']);
            // $parent.find('main.drawer').show();
            // $parent.find('section.edit').hide();
            // $parent.find('main.drawer header, main.drawer footer').show();
            //
            // $parent.find('.pagination.next').removeClass('disabled');
            // if (data['currentlyShown'] >= data['totalAvailable']) {
            //     $parent.find('.pagination.next').addClass('disabled');
            // }
            //
            // $parent.find('.accordion-state').click();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}
