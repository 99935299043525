/**
 * Collection of methods to handle the entries (displaying, editing, new entries)
 */

// we have to prevent older views from showing up
var currentEntryId = null;

// clicking on the entry will open it with the detail view
$(document).on('click', '.item-container .table-row .entry-opener', function() {
    var $parentEntry = $(this).parents('.item-container');

    if ($parentEntry.hasClass('open')) {
        // this happens when you close an already open entry
        // other closing calls are done when the next item is loaded
        $parentEntry.removeClass('open').find('main.item-content-container').slideUp();
    } else {
        var $parentOverview = $(this).parents('.overview-container');
        $parentOverview.find('.item-content-container').slideUp().removeClass('open');

        fetchEntry($parentOverview, $parentEntry);
    }
});

// clicking on the close icon will close this detail view
$(document).on('click', '.item-content-container .item-header-actions .icon-kruis-1', function() {
    var $parentEntry = $(this).parents('.item-container');

    $parentEntry.removeClass('open').find('main.item-content-container').slideUp();
});

// clicking on the photo icon will open this entry with the detail view and switch to the portfolio tab
$(document).on('click', '.item-container .table-row .icon-image', function() {
    var $parentEntry = $(this).parents('.item-container');

    if ($parentEntry.hasClass('open')) {
        $parentEntry.find('ul.tabs li').eq(1).click();
    } else {
        var $parentOverview = $(this).parents('.overview-container');
        $parentOverview.find('.item-content-container').slideUp().removeClass('open');

        fetchEntry($parentOverview, $parentEntry, '1');
    }
});

// method to load the entry
function fetchEntry($parent, $parentEntry, skipToTab) {
    setLoading();

    var id = $parentEntry.data('id');
    var url = $parent.data('controller') + '/' + id;
    currentEntryId = id;

    var $item = $parentEntry.find('main.item-content-container');

    data = {
        id: id
    };

    $.ajax({
        method: 'GET',
        url: url,
        dataType: 'json',
        success: function(data) {
            if (data['fetchedId'] == currentEntryId) {
                $parent.find('.item-container.open').removeClass('open');
                $parentEntry.addClass('open');

                $item.replaceWith(data['content']);
                $parent.find('.item-container[data-id="' + data['fetchedId'] + '"]').find('.item-content-container').slideDown();

                if (skipToTab) {
                    $parentEntry.find('ul.tabs li').eq(skipToTab).click();
                }

                initGallery($parentEntry, data['fetchedId']);
                initDraggableModels($parent, $parentEntry);
            }
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}

// some content blocks have multiple panels of content, there is a switcher element
// to allow the user to select the desired panel
// first we generate a list with the titel of the panels, with another event to handle the selection
$(document).on('click', '.switchable-content .content-switcher span, .switchable-content-block h2', function() {
    $parent = $(this).parents('.switchable-content');

    var selectablePanels = [];
    $parent.find('.switchable-content-block h2').each(function(index) {
        selectablePanels.push('<li>' + $(this).text() + '</li>');
    });

    $parent.find('.content-switcher').append('<ul>' + selectablePanels.join('') + '</ul>');
    $parent.find('.content-switcher ul').slideDown();
});

// when clicking on a switchable content option, hide the selector, and switch the content
$(document).on('click', '.content-switcher li', function() {
    $parent = $(this).parents('.switchable-content');

    $parent.find('.switchable-content-block').hide();
    $parent.find('.switchable-content-block').eq($(this).index()).show();
    $parent.find('.content-switcher ul').remove();
});