/**
 * Collection of methods to control what content to show, for the tabs in entry views
 */

// handle tab switches
$(document).on('click', 'ul.tabs li', function() {
    // set the correct active tab
    var $parent = $(this).parents('.item-content-container');
    $parent.find('ul.tabs li.active').removeClass('active');
    $(this).addClass('active');

    // show the correct content
    $parent.find('div.tab-content').hide();
    $parent.find('div.tab-content').eq($(this).index()).css('display', 'flex');
});
