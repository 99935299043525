/**
 * Collection of methods to handle editing of entries
 */

// add an entry the selected deleteIds
$(document).on('click', '.icon-add, .icon-edit', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/create';
    if ($(this).hasClass('icon-edit')) {
        url = $parent.data('controller') + '/' + $parentEntry.data('id') + '/edit';
    }

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            $parent.find('main.drawer').hide();
            $parent.find('section.edit').replaceWith(data['content']).show();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// store the form
$(document).on('click', '.form-container .store', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentForm = $(this).parents('.form-container');
    var $form = $parentForm.find('form');
    var data = $form.serialize();
    var action = $form.attr('action');
    var method = 'POST';
    if ($parentForm.hasClass('form-edit')) {
        method = 'PUT';
    }

    // remove previous warnings
    $('.warning').remove();
    $('.in-error').removeClass('in-error');

    $.ajax({
        url: action,
        type: method,
        data: data,
        dataType: 'json',
        success: function(data) {
            fetchOverview($parent);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function(xhr) {
        if (xhr.status == 422) {
            $.each(xhr.responseJSON, function(field, errors) {
                var error = errors.join(', ');
                if ($('input#' + field).length > 0) {
                    $('input#' + field).addClass('in-error');
                    $('input#' + field).parents('.form-line').before('<div class="warning">' + error + '</div>')
                } else if ($('select#' + field).length > 0) {
                    $('select#' + field).parent().addClass('in-error');
                    $('select#' + field).parents('.form-line').before('<div class="warning">' + error + '</div>')
                } else {
                    alert(error);
                }
            });
        } else {
            ajaxError();
        }
    });
});

// cancel the form, switch back to the overview
$(document).on('click', '.form-container .cancel', function() {
    var $parent = $(this).parents('.overview-container');
    $parent.find('main.drawer').show();
    $parent.find('section.edit').hide();
});

// fetch the contacts for that company
$(document).on('change', '.company-selector', function() {
    var $that = $(this);
    var $parent = $that.parents('#contact_person_selectors');
    var route = $parent.data('route');
    var data = {cid: $that.find('option:selected').val()};
    var contacts_select = $('#' + $that.data('control'));

    $.post(
        route,
        data,
        function(data) {
            if (data.result == 'ok') {
                contacts_select.find('option').remove();
                $.each(data.contactsResult, function (index, element) {
                    contacts_select.append('<option value="' + index + '">' + element + '</option>');
                });
            }
        },
        'json'
    );
});

// open custom dropdown with multiple checkboxes
$(document).on('click', '.form-container .checkboxes-selected', function() {
    $fields = $(this).parents('.fields');
    $fields.find('ul.checkboxes-dropdown').show();
    $fields.find('.dropdown-closer').show();
});
// close custom dropdown with multiple checkboxes, and update the text preview with the selected checkboxes
$(document).on('click', '.form-container .dropdown-closer', function() {
    $fields = $(this).parents('.fields');
    $fields.find('ul.checkboxes-dropdown').hide();
    $fields.find('.dropdown-closer').hide();

    // see what checkboxes are selected, and update the preview
    var selectedTexts = [];
    $(this).parents('.fields').find('ul.checkboxes-dropdown input:checked').each(function() {
        $label = $(this).parents('li').find('label');
        selectedTexts.push('<span>' + $label.text() + '</span>');
    });
    $('.form-container .checkboxes-selected').html(selectedTexts);
});
