// clicking on one of the tabs for a project panel view will have to clear the previous selected model
// this makes sure all the price views are always up to date
$(document).on('click', '.reset-model-selection', function() {
    var $parentEntry = $(this).parents('.item-container');
    $('.clearable-model-container').html('<h2>Selecteer een model</h2>');
});

// clicking on the plus sign adds the already selected models to a new or existing group
// this is used in the 'huidige selectie' panel
$(document).on('click', '.plus-add-models-to-group', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/add-models-to-group';

    // check if we are creating a new group, or we are adding to an already existing group
    var name = $(this).data('name');
    if ($(this).hasClass('plus-add-models-to-new-group')) {
        name = $parentEntry.find('.groupname').val();
    }

    var data = {
        id: $parentEntry.data('id'),
        name: name,
    };

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            // update the HTML and clear any selected model checkboxes
            $parentEntry.find('.grouped-models-container').replaceWith(data['content']);
            $('.icon-check').removeClass('icon-check').addClass('icon-checkbox-empty');
            initDraggableModels();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// clicking on the cross sign removes any once introduced models from the project
// this is used in the 'huidige selectie' panel
$(document).on('click', '.remove-introduced-models', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/remove-introduced-models/' + $parentEntry.data('id');

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            $parentEntry.find('.grouped-models[data-area-type="ooit_voorgesteld"] li').remove();
        }
    }).done(function() {
        $('#voorgestelde-modellen').html('');
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// clicking on the arrow adds extra project costs to the project
// this is used in the 'project details' panel > 'extra kosten project' subview
$(document).on('click', '.add-extra-project-costs-button', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/add-project-extra-costs';

    // build up the data, based on the two inputfields and the project ID
    var data = {
        id: $parentEntry.data('id'),
        omschrijving: $parentEntry.find('#add-extra-project-costs-label').val(),
        bedrag: $parentEntry.find('#add-extra-project-costs-value').val()
    };

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            $parentEntry.find('.extra-project-costs-container').replaceWith(data['content']);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// clicking on the arrow adds extra project costs to the project
// this is used in the 'project details' panel > 'extra kosten project' subview
$(document).on('click', '.remove-extra-project-costs-button', function() {
    setLoading();



    var $row = $(this).parents('.entry-info-container');
    var url = '/project/remove-project-extra-costs';
    var id = $(this).data('project-kosten-id');
    var data = {
        id: id
    };

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function() {
            $row.remove();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// clicking on a booked model will show the costs for that model in this project which a client will have to pay
// this is used in the 'geboekte modellen' panel
$(document).on('click', '.selected-booked-model', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $parent.data('controller') + '/get-booked-model-costs/' + $parentEntry.data('id') + '/' + $(this).data('model-id');

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            $parentEntry.find('.selected-booked-model-container').replaceWith(data['content']);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// clicking on a booked model will show the costs for that model and the payments to the model
// this is used in the 'uitbetaling modellen' panel
$(document).on('click', '.payment-booked-model', function() {
    setLoading();

    var $parent = $(this).closest('.overview-container');
    var $parentEntry = $(this).closest('.item-container');
    var url = $parent.data('controller') + '/get-booked-model-payment/' + $parentEntry.data('id') + '/' + $(this).data('model-id');

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            $parentEntry.find('.payment-booked-model-container').replaceWith(data['content']);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// method to handle dragging of models, from the left container to one of the two right containers
// this is used in the 'huidige selectie' panel
function initDraggableModels($parent, $parentEntry) {
    // make models draggable, and make sure they snap back to their original position if dropping fails
    $('.droppable').draggable({
        revert: 'invalid'
    });

    // make the droppable area droppable for the dragged models, and add a class when dragging, so the client
    // can clearly see where the model can be placed
    $('.projects-droparea').droppable({
        accept: '.droppable',
        activeClass: 'ui-state-hover',
        drop: function(event, ui) {
            var droparea = $(this);

            if (droparea.data('drop-type') === 'add-model-to-group') {
                var model_id = ui.draggable.data('model-id');
                var group = droparea.data('model-group-id');
                var url = $parent.data('controller') + '/add-model-to-group';

                // build up the data, and tell the server in what group the model is dropped
                var data = {
                    group: group,
                    project_id: $parentEntry.data('id'),
                    model_id: model_id
                };

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    dataType: 'json',
                    success: function(data) {
                        // update the total project costs, for when models get removed
                        $('.project-total-costs').text(data['project_costs']);

                        refreshBookedModels();
                        refreshPaymentModels();
                    }
                }).always(function() {
                    unsetLoading();
                }).fail(function() {
                    // todo: remove the element if something went wrong, and unhide the original
                    ajaxError();
                });

                // hide the original version, will be removed permanently if the ajax call is successfull
                ui.draggable.hide();

                var newLi = '<li class="droppable" data-model-id="' + model_id + '">' + ui.draggable.text() + '</li>';

                // create a new element in the dropped area
                droparea.append(newLi);

                // first clear the lists in 'geboekte modellen' and 'uitbetaling modellen'
                $('ul.selected-booked-models').replaceWith('<ul class="selected-booked-models"></ul>')
                $('ul.payment-booked-models').replaceWith('<ul class="payment-booked-models"></ul>')

                // then add the current elements
                $('ul.grouped-models-droparea[data-area-type="geboekt"] li').each(function(index) {
                    // also create the new element in the other tabs, and set the correct class
                    var selectedLi = $(this).clone();

                    $('ul.selected-booked-models').append(selectedLi);
                    $('ul.selected-booked-models li.grouped-model').removeClass('grouped-model').addClass('selected-booked-model');

                    var paymentLi = $(this).clone();
                    $('ul.payment-booked-models').append(paymentLi);
                    $('ul.payment-booked-models li.grouped-model').removeClass('grouped-model').addClass('payment-booked-model');
                });

                // make the new dropped element draggable again
                $(this).find('li').last().draggable({
                    revert: 'invalid'
                });
            } else if (ui.draggable.hasClass('group')) {
                var groupId = ui.draggable.data('model-group-id');
                var url = $parent.data('controller') + '/model-status-group';
                var data = {
                    dropped_in: $(this).data('area-type'),
                    project_id: $parentEntry.data('id'),
                    group_id: groupId
                };
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    dataType: 'json',
                    success: function(data) {
                        // update the total project costs, for when models get removed
                        $('.project-total-costs').text(data['project_costs']);

                        ui.draggable.hide();

                        for (var i=0; i<=data['models'].length -1; i++) {
                            var li = '<li class="droppable" data-model-id="' + data['models'][i]['id'] + '">' + data['models'][i]['name'] + '</li>';
                            droparea.append(li);
                            $('li', droparea).each(function() {
                                $(this).draggable({
                                    revert: 'invalid'
                                });
                            });
                        }

                        // Update other parts of the DOM with new data
                        refreshBookedModels();
                        refreshPaymentModels();
                    }
                }).always(function() {
                    unsetLoading();
                }).fail(function() {
                    // todo: remove the element if something went wrong, and unhide the original
                    ajaxError();
                });
            } else {
                var model_id = ui.draggable.data('model-id');
                var url = $parent.data('controller') + '/model-status';

                // build up the data, and tell the server in what group the model is dropped
                var data = {
                    dropped_in: $(this).data('area-type'),
                    project_id: $parentEntry.data('id'),
                    model_id: model_id
                };

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    dataType: 'json',
                    success: function(data) {
                        // update the total project costs, for when models get removed
                        $('.project-total-costs').text(data['project_costs']);

                        refreshBookedModels();
                        refreshPaymentModels();
                    }
                }).always(function() {
                    unsetLoading();
                }).fail(function() {
                    // todo: remove the element if something went wrong, and unhide the original
                    ajaxError();
                });

                // hide the original version, will be removed permanently if the ajax call is successfull
                ui.draggable.hide();

                var newLi = '<li class="droppable" data-model-id="' + model_id + '">' + ui.draggable.text() + '</li>';

                // create a new element in the dropped area
                droparea.append(newLi);

                // first clear the lists in 'geboekte modellen' and 'uitbetaling modellen'
                $('ul.selected-booked-models').replaceWith('<ul class="selected-booked-models"></ul>')
                $('ul.payment-booked-models').replaceWith('<ul class="payment-booked-models"></ul>')

                // then add the current elements
                $('ul.grouped-models-droparea[data-area-type="geboekt"] li').each(function(index) {
                    // also create the new element in the other tabs, and set the correct class
                    var selectedLi = $(this).clone();

                    $('ul.selected-booked-models').append(selectedLi);
                    $('ul.selected-booked-models li.grouped-model').removeClass('grouped-model').addClass('selected-booked-model');

                    var paymentLi = $(this).clone();
                    $('ul.payment-booked-models').append(paymentLi);
                    $('ul.payment-booked-models li.grouped-model').removeClass('grouped-model').addClass('payment-booked-model');
                });

                // make the new dropped element draggable again
                $(this).find('li').last().draggable({
                    revert: 'invalid'
                });
            }
        }
    });
}

// adding extra costs for just one model in this project
$(document).on('click', '.selected-booked-model-container .icon-arrow-up', function() {
    var $parent = $(this).closest('.overview-container');
    var $parentModel = $(this).closest('.selected-booked-model-container');

    handleBookedModelCosts($parent, $parentModel);
});

// handle changes in the model costs label and/or values Todo: refresh the left container after change
$(document).on('change', '.selected-booked-model-container .project-model-costs-field', function() {
    var $parent = $(this).closest('.overview-container');
    var $parentModel = $(this).closest('.selected-booked-model-container');

    handleBookedModelCosts($parent, $parentModel);
});

// general method to handle updates done to the 'geboekte modellen' view
function handleBookedModelCosts($parent, $parentModel) {
    setLoading();

    var url = $parent.data('controller') + '/project-model-extra-costs';
    var data = $parentModel.find('form').serialize();

    // disable the input fields, so further editing cannot be done untill the server responses
    // this is done to prevent the user from editing a second field, which will be replaced with
    // the serve response, which feels buggy
    $('.booked-model-costs-field').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            $parentModel.replaceWith(data['content']);
            $('.booked-model-costs-field').prop('disabled', false);
            $('#booked-models-left').html(data.booked_models_left_content);
            // $('.project-total-costs').text(data['project_costs']);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}

// remove booked model extra costs from a project
// todo: this method could perhaps be integrated in the above method, to reduce some code?
$(document).on('click', '.selected-booked-model-container .icon-kruis-1', function() {
    var $parent = $(this).closest('.overview-container');
    var $parentModel = $(this).closest('.selected-booked-model-container');

    setLoading();

    var url = $parent.data('controller') + '/remove-project-model-extra-costs/' ;
    var data = {
        'kosten_id': $(this).data('kosten-id'),
        'project_id': $(this).data('project-id'),
        'model_id': $(this).data('model-id')
    };

    $('.booked-model-costs-field').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            $parentModel.replaceWith(data['content']);
            $('.booked-model-costs-field').prop('disabled', false);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// set the models as payed for a project
$(document).on('click', '.models-payed', function() {
    var $parent = $(this).closest('.overview-container');
    var $parentEntry = $(this).closest('.item-container');

    setLoading();

    var url = $parent.data('controller') + '/set-models-payed/';
    var data = {
        'project_id': $parentEntry.data('id'),
        'models_payed': ($(this).is(':checked')) ? '1' : '0'
    };

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            if (parseInt(data.models_payed) == 1) {
                $parentEntry.find('.indication-dot').show();
            } else {
                $parentEntry.find('.indication-dot').hide();
            }
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// set the project as payed
$(document).on('click', '.project-payed', function() {
    var $parent = $(this).closest('.overview-container');
    var $parentEntry = $(this).closest('.item-container');

    setLoading();

    var url = $parent.data('controller') + '/set-project-payed/';
    var data = {
        'project_id': $parentEntry.data('id'),
        'project_payed': ($(this).is(':checked')) ? '1' : '0'
    };

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// handle changes in the payment to models values
$(document).on('change', '.payment-booked-model-container .project-model-costs-field', function() {
    var $parent = $(this).closest('.overview-container');
    var $parentModel = $(this).closest('.payment-booked-model-container');

    setLoading();

    var url = $parent.data('controller') + '/payment-model-costs';
    var data = $parentModel.find('form').serialize();

    // disable the input fields, so further editing cannot be done untill the server responses
    // this is done to prevent the user from editing a second field, which will be replaced with
    // the serve response, which feels buggy
    $('.booked-model-costs-field').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function(data) {
            $parentModel.replaceWith(data['content']);
            $('.booked-model-costs-field').prop('disabled', false);
            $('#payment-of-models-left').html(data.payment_of_models_left_content);
            // $('.project-total-costs').text(data['project_costs']);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

$(document).on('click', '.generate-project-pdf-container button', function(e) {
    e.preventDefault();

    var $parent = $(this).closest('.overview-container');
    var $parentEntry = $(this).closest('.item-container');
    var url = $parent.data('controller') + '/pdf/' + $parentEntry.data('id') + '/' + $('.generate-project-pdf-container select option:selected').val();

    window.open(url);
});


function refreshBookedModels() {
    var target = $('#booked-models-left');
    var projectId = target.data('project-id');

    $.ajax({
        url: 'project/partial-booked-models/'+projectId,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            var content = data['content'];
            target.html(content);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}

function refreshPaymentModels() {
    var target = $('#payment-of-models-left');
    var projectId = target.data('project-id');

    $.ajax({
        url: 'project/partial-payment-models/'+projectId,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            var content = data['content'];
            target.html(content);
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
}

$(document).on('click', '.open-close', function(){
    $(this).find('.grouped-models').slideToggle('slow');
});
