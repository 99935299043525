/**
 * Collection of default methods, usable for all types of content
 */

$(document).ready(function() {
    // this setup makes sure we don't run into token exceptions
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

var loading = false;
function setLoading() {
    $('#loading-indicator').show();
    loading = true;
}
function unsetLoading() {
    $('#loading-indicator').hide();
    loading = false;
}

function ajaxError() {
    alert('Er ging iets fout bij het ophalen van de data');
}