/**
 * Collection of methods to handle specific tasks for contacts
 */

// add an entry
$(document).on('click', '.icon-add-contact', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $(this).data('route');

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            $parent.find('main.drawer').hide();
            $parent.find('section.edit').replaceWith(data['content']).show();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// edit an entry
$(document).on('click', '.edit-contact-person', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $(this).data('route');

    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            $parent.find('main.drawer').hide();
            $parent.find('section.edit').replaceWith(data['content']).show();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});

// edit an entry
$(document).on('click', '.delete-contact-person', function() {
    setLoading();

    var $parent = $(this).parents('.overview-container');
    var $parentEntry = $(this).parents('.item-container');
    var url = $(this).data('route');

    $.ajax({
        url: url,
        type: 'POST',
        data: {_method: 'delete'},
        dataType: 'json',
        success: function(data) {
            $('#contact_person_' + data.removed).remove();
        }
    }).always(function() {
        unsetLoading();
    }).fail(function() {
        ajaxError();
    });
});